<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-10 col-lg-11 col-xl-8 m-auto">
        <AddContactBySearch></AddContactBySearch>


        <div class="card">
          <div class="card-header pb-0"><h1>Select Contact Type</h1></div>
          <div class="card-content pb-1">
            <div class="card-body">
              <div class="row pb-2">
                <div class="col-xs-12 col-sm-12">
                  <div class="mb-xs-3">
                    <div class="search-container position-relative p-0 m-0 position-relative">
                      <div class="input-group input-group-merge">
                        <select name="" id="" class="form-control" v-model="contactProfile.form.group">
                          <option value="customer">Customer</option>
                          <option value="supplier">Supplier</option>
                          <option value="employee">Employee</option>
                          <option value="member">Member</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <vForm :validation-schema="contactProfile.config.validation_schema" @submit="submitForm">
          <div class="card">
            <div class="card-header card-title">
              <h1 class="card-title">Manually Add To Contact</h1>
            </div>
            <div class="card-content collapse show" aria-expanded="false">

              <div class="card-body">
                <div class="row">
                  <template v-for="field in contactProfile.config.fields">
                    <div v-if="field.type === 'heading'" class="col-xs-12 col-md-12"><h4 class="mt-2">{{ field.title }}</h4>
                      <hr>
                    </div>

                    <template v-else>

                      <div class="col-md-6 col-sm-6 col-xs-12" v-if="!field.isMulti">
                        <div class="mb-1">
                          <label class="form-label" :class="{'required': field.isRequired}" :for="field.id">{{ field.title }}</label>
                          <vField
                              :class="{'is-invalid' : !!field.error}"
                              v-if="field.isItem"
                              type="text"
                              class="form-control rounded-0"
                              :id="field.id"
                              :placeholder="`Enter your ${field.title}`"
                              v-model="contactProfile.singleItemFields[field.id]"
                              :name="field.id"
                          ></vField>
                          <vField
                              v-else
                              type="text"
                              class="form-control rounded-0"
                              :class="{'required': field.isRequired, 'is-invalid' : !!field.error}"
                              :id="field.id"
                              :placeholder="`Enter your ${field.title}`"
                              v-model="contactProfile.form[field.id]"
                              :name="field.id"
                          ></vField>
                          <ErrorMessage :name="field.id" class="invalid-feedback d-block"/>

                        </div>
                      </div>



                      <!-- Other Information Generate Multi Items-->
                      <template v-else>


                        <div class="col-xs-12 col-md-12 mb-75">
                          <div class="row mt-2">
                            <div
                                v-for="(input, index) in contactProfile.multiFormItems[field.id]"
                                class="col-md-6 col-sm-6 col-xs-12"
                            >
                              <label
                                  :style="{visibility: index === 0?'visible':'hidden'}"
                                  class="form-label"
                                  :class="{'required': field.isRequired}"
                                  :for="`${field.id}_${index}`">{{ field.title }}
                              </label>
                              <div class="input-group">
                                <vField
                                    :class="{'is-invalid' : !!input.error}"
                                    type="text"
                                    class="form-control rounded-0"
                                    :placeholder="`Enter your ${field.title}`"
                                    v-model="input.value"
                                    :name="`${field.id}_${index}`"
                                ></vField>

                                <!-- Dont generate side buttons if field does not have attrs -->
                                <template v-if="!has(field, 'attrs') ">
                                  <button v-if="index === contactProfile.multiFormItems[field.id].length - 1"
                                          v-on:click.prevent="this.addItemToMultiItemForm(field.id)"
                                          type="button"
                                          class="rounded-0 ms-1 btn btn-icon btn-primary waves-effect waves-float waves-light"
                                          title="Add more items">
                                    <i class="fas fa-plus"></i>
                                  </button>

                                  <button
                                      v-else
                                      v-on:click.prevent="this.removeItemFromMultiItemForm(field.id, index)"
                                      type="button"
                                      class="rounded-0 ms-1 btn btn-icon btn-danger waves-effect waves-float waves-light"
                                      title="Remove items"
                                  >
                                    <i class="fas fa-times"></i>
                                  </button>
                                </template>
                              </div>
                              <ErrorMessage :name="`${field.id}_${index}`" class="invalid-feedback d-block"/>
                            </div>
                          </div>
                        </div>

                      </template>

                    </template>


                  </template>
                  <!-- main field loop finishes -->
                  <MultiItemGroup @dataChanged="multiItemDataUpdate"></MultiItemGroup>

                </div>

              </div>
            </div>
            <div class="card-footer">
              <button
                  type="submit"
                  class="rounded-0 btn btn-lg btn-icon btn-icon btn-primary waves-effect waves-float waves-light"
                  title="Add Your Profile Info"
              >
                <i class="fas fa-save"></i>
                Save
              </button>
            </div>
          </div>
        </vForm>

      </div>


    </div>


  </div>
</template>

<script>

import handleContact from "@/services/modules/contact";
import addContactByUserSearch from "@/components/molecule/contact/AddContactByUserSearch";
import {inject} from "vue";
import {has, forEach} from 'lodash'
import MultiItemGroup from "@/components/molecule/contact/MultiItemGroup";

export default {

  name: 'ContactProfileForm',
  components: {
    AddContactBySearch: addContactByUserSearch,
    MultiItemGroup: MultiItemGroup
  },
  data() {
    return {
      contactProfile: {
        config: {
          validation_schema:{
            full_name: 'required',
            phone_0: 'required',
            email_0: 'required|email',
          },
          fields: [
            {
              type: 'heading',
              title: 'Personal Information',
            },
            {
              error: '',
              type: 'text',
              isMulti: false,
              isItem: false,
              isRequired: true,
              title: 'Full Name',
              id: 'full_name',
            },
            {
              error: '',
              type: 'text',
              isMulti: true,
              isItem: true,
              isRequired: true,
              title: 'Phone',
              id: 'phone',
            },
            {
              error: '',
              type: 'text',
              isMulti: true,
              isItem: true,
              isRequired: true,
              title: 'Email',
              id: 'email',
            },
            {
              error: '',
              type: 'text',
              isMulti: false,
              isItem: true,
              isRequired: false,
              title: 'Company',
              id: 'company',
            },
            {
              error: '',
              type: 'text',
              isMulti: false,
              isItem: true,
              isRequired: false,
              title: 'Job Title',
              id: 'job_title',
            },
            {
              error: '',
              type: 'text',
              isMulti: false,
              isItem: true,
              isRequired: false,
              title: 'Department',
              id: 'department',
            },
            {
              error: '',
              type: 'text',
              isMulti: false,
              isItem: true,
              isRequired: false,
              title: 'Date Of Birth',
              id: 'date_of_birth',
            },
            {
              error: '',
              type: 'text',
              isMulti: false,
              isItem: true,
              isRequired: false,
              title: 'Father Name',
              id: 'father_name',
            },
            {
              error: '',
              type: 'text',
              isMulti: false,
              isItem: true,
              isRequired: false,
              title: 'Mother Name',
              id: 'mother_name',
            },
            {
              error: '',
              type: 'text',
              isMulti: false,
              isItem: true,
              isRequired: false,
              title: 'Spouse Name',
              id: 'spouse_name',
            },
            {
              error: '',
              type: 'text',
              isMulti: false,
              isItem: true,
              isRequired: false,
              title: 'NID',
              id: 'nid',
            },
            {
              type: 'heading',
              title: 'Location Details',
            },
            {
              error: '',
              type: 'text',
              isMulti: false,
              isItem: false,
              isRequired: false,
              title: 'Country',
              id: 'country',
            },
            {
              error: '',
              type: 'text',
              isMulti: false,
              isItem: false,
              isRequired: false,
              title: 'District',
              id: 'district',
            },
            {
              error: '',
              type: 'text',
              isMulti: false,
              isItem: false,
              isRequired: false,
              title: 'Police Station',
              id: 'police_station',
            },
            {
              error: '',
              type: 'text',
              isMulti: false,
              isItem: false,
              isRequired: false,
              title: 'Area',
              id: 'area',
            },
            {
              error: '',
              type: 'text',
              isMulti: false,
              isItem: false,
              isRequired: false,
              title: 'Post Code',
              id: 'post_code',
            },
          ],

          allowedMultipleFields: [
            'email',
            'phone',
          ],

          allowedGroupFields: {
            education: [],
            experience: [],
            skill: [],
          }
        },

        defaultSchema:{
          phone: 'required',
          email: 'required|email',
        },

        form: {
          full_name: '',
          country: '',
          district: '',
          police_station: '',
          area: '',
          post_code: '',
          location: '',
          group: 'supplier',
        },

        singleItemFields: {
          address: '',
          company: '',
          job_title: '',
          department: '',
          date_of_birth: '',
          nid: '',
          father_name: '',
          mother_name: '',
          spouse_name : '',
        },

        multiFormItems: {},
      },
    }
  },

  methods: {
    hasAttr(field){
      return this.has(field, 'attrs');
    },
    toUpper(string) {
      if (!!!string) return ''
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    addItemToMultiItemForm(multiItemType) {
      this.contactProfile.multiFormItems[multiItemType].push({
        field: multiItemType,
        value: ''
      })

      let fieldName = `${multiItemType}_${this.contactProfile.multiFormItems[multiItemType].length - 1}`
      if(!has(this.contactProfile.config.validation_schema, fieldName)){
        this.contactProfile.config.validation_schema[fieldName] = this.contactProfile.defaultSchema[multiItemType]
      }

    },
    removeItemFromMultiItemForm(key, index) {
      delete this.contactProfile.config.validation_schema[`${key}_${this.contactProfile.multiFormItems[key].length - 1}`]
      this.contactProfile.multiFormItems[key].splice(index, 1)
    },
    createContactProfileItems() {
      let key, profileItems = [], item;
      for (key in this.contactProfile.singleItemFields) {
        if (this.contactProfile.singleItemFields.hasOwnProperty(key)) {
          const val = this.contactProfile.singleItemFields[key];
          if (!!val) {
            profileItems.push({
              field: key,
              value: this.contactProfile.singleItemFields[key]
            })
          }
        }
      }

      for (key in this.contactProfile.multiFormItems) {
        if (this.contactProfile.multiFormItems.hasOwnProperty(key)) {
          const items = this.contactProfile.multiFormItems[key];
          items.forEach((item) => {
            if (!!item && !!item.value) {
              profileItems.push(item)
            }
          })
        }
      }

      return profileItems;
    },
    async submitForm() {
      let profileItems = this.createContactProfileItems();
      let form = this.contactProfile.form;
      if (!!profileItems.length && profileItems.length > 0) {
        form['profile_items'] = profileItems
      }

      forEach(this.contactProfile.config.allowedGroupFields, (groups, key) => {
        groups.forEach((item, index) => {
          delete item.file
          if(item.value !== '') form['profile_items'].push({
            ...item,
            field: key
          })
        })
      })


      this.loading = true;

      try {
        let res = await this.storeContactProfile(form)
        if (!res.status) {
          this.showError(res.message)
        }
        if (res.status) {
          this.showSuccess(res.message)
          this.$router.push({name: 'contact.user.index'});
        }
      } catch (err) {
        if (!err.response) {
          return this.showError('Something is wrong.!!')
        }
        if (err.response.data.message) {
          return this.showError(err.response.data.message)
        }
        if (err.response.statusText) {
          return this.showError(err.response.statusText)
        }
      } finally {
        this.loading = false
      }
    },

    multiItemDataUpdate(groupItemsData) {
      groupItemsData.forEach((components, index) => {
        let key = components.key;
        let groups = components.items;
        let newComponents = []

        groups.forEach((group, index) => {
          let logo = group.logo
          let fields = group.fields
          let item = {
            field: '',
            value: '',
            file: logo,
            items: [],
          }
          fields.forEach((field, index) => {
            if(index > 0){
              let fieldKey = field.key
              let value = field.value
              if(!!value){
                item.items.push({
                  field: fieldKey,
                  value: value,
                })
              }

            }
          })
          item.field = fields[0].key
          item.value = fields[0].value
          newComponents.push(item)
        })

        this.contactProfile.config.allowedGroupFields[key] = newComponents
      })
    }
  },

  mounted() {
    this.contactProfile.config.allowedMultipleFields.forEach((item) => {
      this.contactProfile.multiFormItems[item] = [
        {
          field: item,
          value: ''
        }
      ]
    })

  },

  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    const {
      storeContactProfile,
      addToUserContact,
      loading,
    } = handleContact()

    return {
      storeContactProfile,
      addToUserContact,
      has,
      forEach,
      loading,
      showError,
      showSuccess
    }
  }
}
</script>

<style scoped>
  .card-title{
    font-size: 2rem;
  }

  .form-label.required:after {
    content:"*";
    color:red;
    font-size: 1rem;
    margin-left: 0.2rem;
  }
</style>
